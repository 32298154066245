// @ts-ignore
import {NavItemType} from "@/components/shared/Navigation/NavigationItem";
import ncNanoId from "@/lib/utils/ncNanoId";
import {MSellerOrigin, TSellerOrigin} from "@/data/rentalioTypes";

export const RENTALIO_NAV: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "https://blog.rentalify.pl/",
    name: "Jak to działa?",
    isNew: true,
    targetBlank: true,
    visible: true,
  },
  {
    id: ncNanoId(),
    href: "https://blog.rentalify.pl/baza-wiedzy/",
    name: "Baza wiedzy",
    isNew: true,
    targetBlank: true,
    visible: true,
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
    isNew: true,
    visible: true,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/ankieta-stan-video-2023",
  //   name: "Ankieta: Stan Video 2023",
  //   isNew: true,
  //   disabled: true,
  // },
  {
    id: ncNanoId(),
    href: "/o-nas",
    name: "O nas",
    isNew: false,
    disabled: false,
    visible: true,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/dev",
  //   name: "Dev",
  //   isNew: false,
  //   disabled: false,
  // },
];
export const RENTALIO_ACCOUNT_NAV = (loggedUserOrigin: TSellerOrigin | undefined): NavItemType[] => {

  return [
    {
      id: 'KONTO_DANE',
      key: '',
      href: "/konto/dane",
      name: "Twoje dane",
      visible: loggedUserOrigin === MSellerOrigin.registered || false,
    },
    {
      id: 'KONTO_DANE_TRANSAKCJE',
      href: "/konto/dane/transakcje",
      name: "Dane do transakcji",
      visible: loggedUserOrigin === MSellerOrigin.registered || false,
    },
    {
      id: 'KONTO_SPRZET_LISTA',
      href: "/konto/sprzet/lista",
      name: "Twój sprzęt",
      visible: loggedUserOrigin === MSellerOrigin.registered || false,
    },
    {
      id: 'KONTO_TRANSAKCJE_NAJEM',
      href: "/konto/transakcje/najem",
      name: "Najem",
      visible: true,
    },
    {
      id: 'KONTO_TRANSAKCJE_WYNAJEM',
      href: "/konto/transakcje/wynajem",
      name: "Wynajem",
      visible: loggedUserOrigin === MSellerOrigin.registered || false,
    },
    {
      id: 'KONTO_WERYFIKACJA',
      href: "/konto/weryfikacja",
      name: "Weryfikacja",
      visible: loggedUserOrigin === MSellerOrigin.registered || false,
    },
    // {
    //   id: ncNanoId(),
    //   href: "/konto/ustawienia",
    //   name: "Ustawienia",
    //   visible: envs.APP_ENV !== 'production'
    // }
  ];
}

