import {TwMainColor} from "@/data/types";

export type TLoadingState = 'loading' | 'done' | 'error';

export interface IFrontendFile {
  galleryId?: number;
  _id: string;
  url: string;
  gearId: string;
  sellerId: string;
  name: string;
  originalName: string;
  isMainPhoto: boolean;
}

export type TTransactionState =
  'UNKNOWN'
  | 'STARTED'
  | 'ACCEPTED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'RATED'
  | 'CANCELLED'
  | 'ERROR';

export interface IUnknownTenantModel {
  name?: string;
  email?: string;
  phoneNumber?: string;
  regulationsConfirmed?: boolean;
  confirmationCode?: string;
}

export interface ITransactionsRequestDTO {
  ownerId?: string,
  tenantId?: string,
  gearId?: string[],
  dateFrom?: string,
  dateTo?: string,
}

export interface ITransactionsStateChangeRequestDTO {
  transactionId?: string,
  ownerId?: string,
  tenantId?: string,
  tenantOrigin?: TSellerOrigin,
  gearId?: string[],
  state?: TTransactionState,
  rating?: number;
  ratingComment?: string;
  collectAddressId?: string | undefined;
  returnAddressId?: string | undefined;
  cancelledByContextId?: string | undefined;
}

export interface ITransactionRatingRequestDTO extends ITransactionsStateChangeRequestDTO {
  rating?: number;
  ratingComment?: string;
}

export interface ITokenModel {
  token: string,
  meta?: {
    [key: string]: any;
  },
  expire: Date,
  dateCreated: Date,
  dateUpdated: Date,
}

export interface ITransactionsDataType {
  _id: string;
  ownerId: string,
  tenantId: string,
  tenantOrigin: TSellerOrigin,
  tenant: ISellerDataType,
  owner: ISellerDataType,
  gears: IGearDataType[],
  price: number,
  dateFrom: Date,
  dateTo: Date,
  state: TTransactionState,
  active: boolean;
  dateCreated: Date;
  dateUpdated: Date;
  reviewOwner?: number;
  reviewOwnerDate?: number;
  reviewOwnerComment?: string;
  reviewTenant?: number;
  reviewTenantDate?: number;
  reviewTenantComment?: string;
  transactionToken: ITokenModel;
  collectAddressId?: string;
  returnAddressId?: string;
  collectAddress?: ITransactionAddress;
  returnAddress?: ITransactionAddress;
  cancelledByContextId?: string;
}

export interface IGearDataType {
  _id: string;
  sellerId: string;
  sellerDisplayName: string;
  sellerProfileName: string;
  setParent: string[];
  slug: string;
  model: string;
  modelName: string;
  manufacturer: string;
  manufacturerName: string;
  customManufacturer: boolean;
  category: string;
  city: string;
  cityName: string;
  voivodeship: string;
  voivodeshipName: string;
  files: IFrontendFile[],
  prices: {
    pricePerDay: number;
    minDays: number;
    maxDays: number;
    _id: string;
  }[],
  deposit: boolean;
  depositAmount: number;
  serialNumber: string;
  gearValue: number;
  info?: {
    sellerInfo?: string;
  }
  setContains: string[];
  mount: string;
  price: number;
  seller?: ISellerDataType;
  meta?: {
    name: string;
  },
  active: boolean;
}

export interface ISeoGearDataType {
  sellerId: string;
  model: string;
  modelName: string;
  manufacturer: string;
  manufacturerName: string;
  category: string;
  cityName: string;
  voivodeshipName: string;
  mount: string;
  slug: string;
  sellerDisplayName: string;
  sellerProfileName: string;
}

export interface ISeoSellerDataType {
  _id: string;
  location: ISellerDataTypeLocation;
  profileName: string;
  name: string;
  info: {
    avatar: string;
  };
  level: TSellerLevel;
  gearCategories: { [key: string]: number };
}

export interface IGearDataResponse {
  count: number,
  results: IGearDataType[]
}

export interface IGoogleUser {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
}

export interface IRatingRatingTransactionGearModel {
  gearId: any,
  gearName: string,
  gearUrl: string,
}

export type TransactionSide = 'owner' | 'tenant';

export const MTransactionSide: {
  [key in TransactionSide]: TransactionSide
} = {
  owner: "owner",
  tenant: "tenant"
}

export interface IRatingRatingModel {
  as: TransactionSide,
  transactionId: any,
  rating: number,
  ratingComment: string,
  ratingDate: Date,
  ratingOwnerProfileName: string,
  ratingOwnerProfileUrl: string,
  ratingOwnerProfileAvatar: string,
  ratingTenantProfileName: string,
  ratingTenantProfileUrl: string,
  ratingTenantProfileAvatar: string,
  transactionGear: IRatingRatingTransactionGearModel[]
}

export interface IRatingDataType {
  _id?: any,
  sellerId: any,
  sellerProfileName: string,
  sellerProfileAvatar: string,
  overallRating: number,
  ratings: IRatingRatingModel[],
}

export type TSellerOrigin = 'unknownTenant' | 'registered' | 'unknown';

export const MSellerOrigin: {
  [key in TSellerOrigin]: TSellerOrigin
} = {
  'unknownTenant': 'unknownTenant',
  'registered': 'registered',
  'unknown': 'unknown'
}

export type TSellerLevel = 'none' | 'basic' | 'pro' | 'master' | 'partner';

export const MSellerLevel: {
  [key in TSellerLevel]: TSellerLevel
} = {
  'none': 'none',
  'basic': 'basic',
  'pro': 'pro',
  'master': 'master',
  'partner': 'partner'
}

export type ITransactionAddressDesignation = 'collect' | 'return';

export interface ITransactionAddress {
  _id?: string;
  address?: string;
  zip?: string;
  city?: string;
  designation?: ITransactionAddressDesignation;
  default?: boolean;
}

export type TSellerSocialMedia = 'instagram' | 'youtube' | 'facebook' | 'linkedin' | 'custom';

export const MSocialMediaTypes: {
  [key in TSellerSocialMedia]: TSellerSocialMedia
} = {
  'instagram': 'instagram',
  'youtube': 'youtube',
  'facebook': 'facebook',
  'linkedin': 'linkedin',
  'custom': 'custom',
}

export const MSocialMediaLinks: {
  [key in TSellerSocialMedia]: string
} = {
  'instagram': 'https://instagram.com/',
  'youtube': 'https://youtube.com/@',
  'facebook': 'https://facebook.com/',
  'linkedin': 'https://linkedin.com/in/',
  'custom': ''
}

export interface ISellerSocialMedia {
  type: TSellerSocialMedia;
  link: string;
}

export interface ISellerDataTypeInfo {
  about?: string;
  cancellation?: string,
  collectionAndReturn?: string,
  additionalRules?: string,
  overallRating?: number;
  overallRatingAmount?: number;
  avatar?: string;
  newsletter?: boolean | null;
  insuranceInterested?: boolean | null;
  socialMedia?: ISellerSocialMedia[];
}

export interface ISellerDataTypeBusiness {
  companyVatIn?: string;
  companyName?: string;
  companyAddress?: string;
  companyZip?: string;
  companyCity?: string;
}

export interface ISellerDataTypeLocation {
  city?: string;
  cityName?: string;
  voivodeship?: string;
  voivodeshipName?: string;
}

export interface ISellerDataTypePersonalData {
  phoneNumber?: string;
  birthday?: string;
}

export interface ISellerDataType {
  id?: string;
  _id?: string;
  profileName?: string;
  name?: string;
  email?: string;
  origin?: TSellerOrigin;
  ratingsId?: string | undefined | IRatingDataType;
  meta?: any;
  googleUser?: IGoogleUser;
  personalData?: ISellerDataTypePersonalData,
  address?: ITransactionAddress[],
  isCompany?: boolean;
  business?: ISellerDataTypeBusiness,

  location?: ISellerDataTypeLocation;
  info?: ISellerDataTypeInfo,
  level?: TSellerLevel;
  active?: boolean;
  dateCreated?: string;
  dateUpdated?: string;
  dateVerified?: string;
  declarationUploaded?: boolean;
  declarationUploadedAt?: string;
  setCheckPro?: string;
}

export interface ILoginAuthResponse {
  user?: ISellerDataType,
  accessToken?: string;
}

export interface INotificationDataType {
  _id?: any;
  sellerId: string;
  content: string;
  template: string;
  templateVersion: string;
  notificationData: any,
  link: string;
  active: boolean;
  dateCreated?: Date;
  dateUpdated?: Date;
}
