'use client';
import React from "react";
import ButtonClose from "@/components/shared/ButtonClose/ButtonClose";
import Logo from "@/components/shared/Logo/Logo";
import {Disclosure} from "@headlessui/react";
import {NavItemType} from "./Navigation.interface";
import {RENTALIO_ACCOUNT_NAV, RENTALIO_NAV} from "@/data/navigation";
import SocialsList from "@/components/shared/SocialsList/SocialsList";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {ctxGetContextUser} from "@/lib/utils/ctx.service";
import ButtonSecondary from "../Button/ButtonSecondary";
import {ILoginAuthResponse} from "@/data/rentalioTypes";
import Link from "next/link";
import {loginBtn} from "@/components/Header/navigation.helpers";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = (
  {
    data = RENTALIO_NAV,
    onClickClose,
  }) => {
  const loggedUser: ILoginAuthResponse['user'] | null = ctxGetContextUser()
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu space-y-2 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <Link
              end
              href={{
                pathname: i.href || undefined,
              }}
              // @ts-ignore
              className={({isActive}) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-800 mt-0.5 ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-1.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    if (item.type === "spacer") {
      return (
        <Disclosure
          key={item.id}
          as="li"
          className="text-white"
        >
          <div className="w-full border-b border-neutral-700"/>
        </Disclosure>
      )
    }
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="dark:text-white"
      >
        <Link
          end
          // @ts-ignore
          className={({isActive}) =>
            `flex w-full px-5 font-medium uppercase tracking-wide text-sm hover:bg-neutral-800 rounded-lg ${
              // @ts-ignore
              isActive ? "text-secondary" : ""
            }`
          }
          href={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={`py-1.5 ${!item.children ? "block w-full" : ""}`}
          >
            {item.name}
          </span>
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1 "
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div
      className={`overflow-y-auto w-full h-screen py-2 transition transform shadow-lg bg-neutral-900 flex flex-col justify-between`}
    >
      <div className="py-3 px-5 border-b border-neutral-700">
        <Logo/>
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Platforma do wypożyczania sprzętu fotograficznego i filmowego.
          </span>
          <div className="flex flex-col my-2 text-neutral-300 text-sm">
            <SocialsList
              itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300"/>
          </div>
        </div>
      </div>
      <div className={'flex flex-col mb-32'}>
        <ul className="flex flex-col items-center py-3 px-5 border-b border-neutral-700">
          {data.map(_renderItem)}
        </ul>
        {
          (!loggedUser?.name || !loggedUser?.origin)
            ? null
            : <ul className="flex flex-col items-center py-3 px-5 border-b border-neutral-700">
              {RENTALIO_ACCOUNT_NAV(loggedUser.origin).map(_renderItem)}
            </ul>
        }

        <div className="py-6 px-5 flex flex-row w-full justify-between">
          <div className="flex flex-start">
            {
              (loggedUser?.name && loggedUser?.origin) &&
              <ButtonSecondary
                className={'mr-5'}
                href="/logout"
              > Wyloguj</ButtonSecondary>
            }
            {
              loginBtn(loggedUser)
            }
          </div>
          <div className="flex flex-end">
            <span className="mt-1">
              <ButtonClose onClick={onClickClose}/>
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose}/>
          </span>
      </div>
    </div>
  );
};

export default NavMobile;
