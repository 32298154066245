"use client"
import {
  UserCircleIcon,
  HomeIcon
} from "@heroicons/react/24/outline";
import React, {useRef} from "react";
import MenuBar from "@/components/shared/MenuBar/MenuBar";
import isInViewport from "@/lib/utils/isInViewport";
import {ctxIsLogged} from "@/lib/utils/ctx.service";
import {usePathname} from "next/navigation";
import Link from "next/link";

let WIN_PREV_POSITION = typeof window !== "undefined"
  ? window.pageYOffset
  : 0;

const MobileFooterNav = () => {
    // console.log('MobileFooterNav props => ', _props);
    const containerRef = useRef<HTMLDivElement>(null);
    const pathname = usePathname();

    // useEffect(() => {
    //   if (typeof window !== "undefined") {
    //     window.addEventListener("scroll", handleEvent)
    //   }
    // }, []);

    // const handleEvent = () => {
    //   if (typeof window !== "undefined") {
    //     window.requestAnimationFrame(showHideHeaderMenu);
    //   }
    // };

    // const showHideHeaderMenu = () => {
    //   let currentScrollPos = typeof window !== "undefined"
    //     ? window.pageYOffset
    //     : 0;
    //   if (!containerRef.current) return;
    //
    //   // SHOW _ HIDE MAIN MENU
    //   if (currentScrollPos > WIN_PREV_POSITION) {
    //     if (
    //       isInViewport(containerRef.current) &&
    //       currentScrollPos - WIN_PREV_POSITION < 80
    //     ) {
    //       return;
    //     }
    //
    //     containerRef.current.classList.add("FooterNav--hide");
    //   } else {
    //     if (
    //       !isInViewport(containerRef.current) &&
    //       WIN_PREV_POSITION - currentScrollPos < 80
    //     ) {
    //       return;
    //     }
    //     containerRef.current.classList.remove("FooterNav--hide");
    //   }
    //
    //   WIN_PREV_POSITION = currentScrollPos;
    // };

    return (
      <div
        ref={containerRef}
        className="lg:hidden FooterNav p-2 bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-700
      transition-transform duration-300 ease-in-out"
      >
        <div className="w-full flex justify-around mx-auto text-sm text-center ">
          {/* MENU */}
          <Link
            key={1}
            href={"/"}
            className={`
            flex flex-col items-center justify-between
              ${(pathname === "/" || pathname === "") ? "text-green-600" : "text-neutral-300/90"}
            `}
          >
            <HomeIcon
              className={
              `w-6 h-6 
                ${(pathname === "/" || pathname === "") ? "text-green-600" : ""}
              `}
            />
            <span className="text-[11px] leading-none mt-1">Główna</span>
          </Link>
          <Link
            key={2}
            href={ctxIsLogged() ? "/konto/dane" : "/login"}
            className={`flex flex-col items-center justify-between ${
              pathname.includes("/konto/") ? "text-green-600" : "text-neutral-300/90"
            }`}
          >
            <UserCircleIcon
              className={
              `w-6 h-6 
                ${pathname.includes("/konto/") ? "text-green-600" : ""}
              `}
            />
            <span className="text-[11px] leading-none mt-1">{ctxIsLogged() ? "Twoje konto" : "Zaloguj"}</span>
          </Link>
          <div
            key={3}
            className={`mr-16 flex flex-col items-center justify-between text-neutral-300/90 ${
              pathname === undefined ? "text-neutral-100" : ""
            }`}
          >
            <MenuBar iconClassName={`w-6 h-6`} className={``}/>
            <span className="text-[11px] leading-none mt-1">Menu</span>
          </div>
        </div>
      </div>
    );
  }
;

export default MobileFooterNav;
