'use client';
import React, {Fragment, useState} from "react";
import ButtonThird from "@/components/shared/Button/ButtonThird";
import {ISellerDataType} from "@/data/rentalioTypes";
import {deleteCookie, getCookie} from "cookies-next";
import {API} from "@/lib/API";
import {useRouter} from 'next/navigation';
import {loginBtn} from "@/components/Header/navigation.helpers";

const LoginRegisterNav: React.FC<{}> = (props, context) => {
  const router = useRouter();
  const [user, setLogged] = useState<ISellerDataType | undefined>(
    getCookie('ctx')
      ? JSON.parse(getCookie('ctx') as string)
      : undefined
  );

  const handleLogout = () => {
    API.AUTH_LOGOUT()
      .then((res: any) => {
        deleteCookie('ctx');
        deleteCookie('token');
        setLogged(undefined);
        router.push('/logout');
      })
  }
  return (
    <>
      {
        loginBtn(user)
      }
      {
        user?.name && user.origin
          ? <Fragment>
            <div className="px-1"/>
            <ButtonThird onClick={handleLogout} className={'border-0 font-light'}>Wyloguj</ButtonThird>
          </Fragment>
          : null
      }
    </>
  )
}
export default LoginRegisterNav;