import {ISellerDataType, MSellerOrigin} from "@/data/rentalioTypes";
import ButtonPrimary from "@/components/shared/Button/ButtonPrimary";
import React from "react";

export const loginBtn = (user: ISellerDataType | undefined | null) => {
  // console.log('loginBtn user => ', user?.name, user?.origin);
  if (user?.name) {
    if (user.origin === MSellerOrigin.registered) {
      return <ButtonPrimary href="/konto/dane">{user?.name || "Twoje konto"}</ButtonPrimary>
    }
    if (user.origin === MSellerOrigin.unknownTenant) {
      return <ButtonPrimary href="/konto/transakcje/najem">{user?.name || "Transakcje"}</ButtonPrimary>
    }
    if(!user.origin){
      return (
        <ButtonPrimary href="/login">Zarejestruj | Zaloguj</ButtonPrimary>
      )
    }
  }
  return (
    <ButtonPrimary href="/login">Zarejestruj | Zaloguj</ButtonPrimary>
  )
}