import Button, {ButtonProps} from "@/components/shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = (
  {
    className = "",
    fontSize = '',
    sizeClass = "",
    ...args
  }) => {
  return (
    <Button
      fontSize={fontSize ? fontSize : undefined}
      sizeClass={sizeClass ? sizeClass : undefined}
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-green-800 hover:bg-green-700 text-neutral-50 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
